import React from 'react';

function Signup() {
  return (
  // <div className="w-full h-screen p-4">
  //   <div class="h-full flex">
  //   <div class="hidden lg:flex w-full lg:w-1/3 rounded-[20px] bg-[#6644FF] justify-around items-center">
  //     <div class="w-full mx-auto h-full flex flex-col justify-around items-center ">
  //       <h1 class="text-white font-bold text-4xl font-sans">Welcome To <br /> <br /> Azizi Africa</h1>
  //       <p class="text-white mt-1">The simplest app to use</p>
  //       <div class="w-[90%] bg-[#000] rounded-md bg-opacity-20 h-[200px]  mt-6">
  //       </div>
  //     </div>
  //   </div>
  //   <div class="flex w-full lg:w-2/3 justify-center items-center bg-white space-y-8">
  //     <div class="w-full px-8 md:px-32 lg:px-24">
  //     <form class="bg-white rounded-md shadow-2xl p-5 ">
  //       <h1 class="w-full text-center text-gray-800 font-bold text-2xl mb-1">Sign In</h1>
  //       <label htmlFor='email'>Email</label>
  //       <div class="w-full flex items-center border-2 mb-4 py-2 px-3 rounded-2xl">
  //         <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
  //         </svg>
  //         <input id="email" class=" pl-2 w-full outline-none border-none" type="email" name="email" placeholder="Email Address" />
  //       </div>
  //       <label htmlFor='password'>Password</label>
  //       <div class="flex items-center border-2 mb-1 py-2 px-3 rounded-2xl ">
  //         <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
  //           <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
  //         </svg>
  //         <input class="pl-2 w-full outline-none border-none" type="password" name="password" id="password" placeholder="Password" />

  //       </div>
  //       <div class="flex justify-end mt-1 ">
  //         <span class="text-sm ml-2 text-[#6644FF] cursor-pointer duration-500 transition-all">Forgot Password ?</span>
  //       </div>
  //       <button type="submit" class="block w-1/2 bg-[#6644FF] mt-5 py-2 mx-auto rounded-md transition-all duration-500 text-white font-bold mb-2">Sign in</button>
  //       <div className=" w-full grid place-items-center mb-2">
  //         <p>
  //           Don't have account? <a href="/signup" class="font-bold hover:underline ml-2 cursor-pointer text-[#6644FF] ">Signup</a>
  //         </p>
  //       </div>

  //     </form>
  //     </div>

  //   </div>
  // </div>
  // </div>

    <section className=' bgSignUp h-screen bg-cover'>
      <div id='mix' className='flex h-full w-full items-center bg-transparent justify-center'>
        <div className='max-w-2xl text-center'>
          <h1 className='text-3xl sm:text-5xl capitalize tracking-widest text-white font-bold lg:text-7xl'>
            Sign Up Form <br /> <br />
            Coming Soon
          </h1>
          <a
            href='/'
            target='_blank'
            className='mt-8 flex flex-col space-y-3 sm:-mx-2 sm:flex-row sm:justify-center sm:space-y-0'
          >
            <button className='transform rounded-md bg-[#008080] px-8 py-2 text-sm font-medium capitalize tracking-wide text-white transition-colors duration-200 focus:outline-none sm:mx-2'>
              Sign In
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Signup;
