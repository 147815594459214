import React, { useEffect, useState } from 'react';
import baseUrl from '../../../BaseUrl';

function SingleOrder({ contact, product }) {
  const [contacts, setContact] = useState();
  const [products, setProducts] = useState();

  const handleContactName = (contact) => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    if (data) {
      const accessToken = data.data.access_token;

      fetch(`${baseUrl}/items/contacts/${contact}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            setContact(res.data.name);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleProductName = (productId) => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    if (data) {
      const accessToken = data.data.access_token;

      fetch(`${baseUrl}/items/products/${productId}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            setProducts(res.data.name);            
          }
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    handleContactName(contact);
    handleProductName(product);
  }, []);

  return (
    <>
      <td data-th='Contact Name' className='p-2 border-r text-start whitespace-nowrap'>{' '}
        {contacts ? (
          contacts
        ) : (
          <p className='whitespace-nowrap' >Loading...</p>
        )}
      </td>
      <td data-th='Product Name' className='p-2 border-r text-start whitespace-nowrap'>{' '}
        {products ? (
          products 
        ) : (
          <p className='whitespace-nowrap' >Loading...</p>
        )}
      </td>   
    </>    
      
    
  );
}

export default SingleOrder;
