import React from 'react';
// import { MdContacts } from 'react-icons/md';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router';

const Sidebar = ({ sidebar, setSidebar }) => {
  const navigate = useNavigate();
  const handleLogout = () => {    
    sessionStorage.clear();
    localStorage.clear();
    navigate('/login');
  };
  const isActiveLink = (link) => {
    return location.pathname === `/${link}` || (location.pathname === '/' && link === 'contacts_products');
  };
   
  return (
    <div
      className={` ${
        sidebar ? '' : 'hidden'
      } z-50 left-0 absolute md:static md:block w-[290px] shadow-xl h-full rounded-md bg-white p-2`}
    >
      <div
        onClick={() => {
          setSidebar(false);
          navigate('/contacts_products');
        }}
        className={`${
          isActiveLink('contacts_products') ? 'bg-[#0080804D]' : 'bg-gray-100'
        } hover:cursor-pointer w-full h-[40px] text-[#008080]  flex mb-2 items-center rounded-md`}
      >
        <HiOutlineShoppingBag className='text-[25px] w-[30px] mx-2' />
        <p className='flex-1 font-semibold'>Agro-Proccessor</p>
      </div>      
      <div
        onClick={() => {
          setSidebar(false);
          navigate('/orders');
        }}
        className={`${
          isActiveLink('orders') ? 'bg-[#0080804D]' : 'bg-gray-100'
        } hover:cursor-pointer w-full h-[40px] text-[#008080]  flex mb-2 items-center rounded-md`}
      >
        <HiOutlineShoppingCart className='text-[25px] w-[30px] mx-2' />
        <p className='flex-1 font-semibold'>Orders</p>
      </div>
      <p
        onClick={handleLogout}
        className='w-full flex py-4 items-center font-semibold hover:text-red-600 cursor-pointer'
      >
        <FiLogOut className='mx-3 text-[20px]' /> Sign Out
      </p>
    </div>
  );
};

export default Sidebar;
