import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import baseUrl from '../../../BaseUrl';

let json = {};

function OrderForm() {
  const [isSaved, setIsSaved] = useState(false);
  const [btnLoad, setbtnLoad] = useState(false);
  const [savedFormData, setSavedFormData] = useState(false);
  const [initialvalues, setInitialValues] = useState(json);
  const [Users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data?.data?.access_token;

    fetch(`${baseUrl}/items/locations/`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLocations(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data.data.access_token;

    fetch(`${baseUrl}/users`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        let ambassadorId = '78183746-3ca1-455f-9652-a14e7792f026';
        const ambassadors = res.data.filter((ambassador) => {
          return ambassador.role === ambassadorId;
        });
        setUsers(ambassadors);
      });
  }, []);

  const handleInterchangeValue = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    json[key] = value;
    setInitialValues(json);
    initialvalues;
  };

  const [formData, setFormData] = useState({
    business_description: '',
    landmark_description: '',
    business_name: '',
    whatsapp_number: '',
    name: '',
    contact_ambassador: '',
    landmark_photo: '',
    location_or_landmark: '',
  });

  function deliveryLocation(info) {
    const data = info.latLng;
    sessionStorage.setItem('lat', JSON.stringify(data.lat));
    sessionStorage.setItem('long', JSON.stringify(data.lng));
  }

  function initAutocomplete() {
    const input = document.getElementById('locationAddress2');
    const searchBox = new window.google.maps.places.SearchBox(input);
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }
      places.forEach((place) => {
        deliveryLocation({
          place_id: place.place_id,
          latLng: place.geometry.location.toJSON(),
          type: 'search',
        });
      });
    });
  }

  const script = document.createElement('script');
  script.src =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyBziMvajtCVRMk7ogCkiIyymQLfUtJBlC0&libraries=places&v=weekly';
  script.async = true;
  script.defer = true;
  script.onload = () => {
    initAutocomplete();
  };
  document.body.appendChild(script);

  const handleGoBack = () => {
    window.history.back();    
  };
  const navigate = useNavigate();
  const onChangeHandler = (e) => {
    setFormData(() => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  };
  const submitFormData = (e) => {
    e.preventDefault();
    setIsSaved(true);
    const latitude = JSON.parse(sessionStorage.getItem('lat'));
    const longitude = JSON.parse(sessionStorage.getItem('long'));
    let finalFormData = {
      ...formData,
      maps_pin: {
        type: 'Point',
        coordinates: [`${latitude}`, `${longitude}`],
      },
    };
    setSavedFormData(finalFormData);
  };

  useEffect(() => {
    if (isSaved) {
      setbtnLoad(true);
      const data = JSON.parse(localStorage.getItem('loginUserData'));
      const accessToken = data.data.access_token;
      fetch(`${baseUrl}/items/contacts`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
        body: JSON.stringify(savedFormData),
      })
        .then((res) => res.json())
        .then((res) => {
          setbtnLoad(false);
          sessionStorage.setItem('contactId', JSON.stringify(res.data.id));
          navigate('/');
        })
        .catch((err) => console.log(err));
    }
  }, [isSaved, savedFormData, navigate]);

  return (
    <div className='w-full relative h-full flex items-center justify-center bg-white  rounded-md '>
      <IoIosArrowRoundBack
        onClick={handleGoBack}
        className='absolute left-6 top-2 text-[30px] h-[40px] w-[40px] bg-gray-300 transition-all duration-500 cursor-pointer hover:bg-[#008080] rounded-[50%]'
      />
      <form
        onSubmit={(e) => submitFormData(e)}
        className='bg-white min-w-[300px]  w-[95%] md:w-3/4 md:min-w-[450px] h-[90%] overflow-y-auto rounded-md shadow-2xl p-3 '
      >
        <h1 className='w-full text-center text-gray-800 font-bold text-2xl'>Add Contact</h1>
        <label htmlFor='location_or_landmark' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Location or Landmark
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <select
              className='pl-2 text-sm font-normal w-full outline-none bg-transparent border-none'
              name='location_or_landmark'
              id='location_or_landmark'
              onKeyUp={(e) => handleInterchangeValue(e)}
              onChange={onChangeHandler}
            >
              <option selected disabled value=''>
                Select Location...
              </option>
              {locations.map(({ id, location_landmark }) => (
                <option key={id} value={id}>
                  {id} {', '} {location_landmark}
                </option>
              ))}
            </select>
          </div>
        </label>

        <label htmlFor='contact_ambassador' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Contact Ambassador
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <select
              className='pl-2 text-sm font-normal w-full outline-none bg-transparent border-none'
              name='contact_ambassador'
              id='contact_ambassador'
              onKeyUp={(e) => handleInterchangeValue(e)}
              onChange={onChangeHandler}
            >
              <option selected disabled value=''>
                Select Ambassador...
              </option>
              {Users.map(({ id, first_name, last_name, email }) => (
                <option key={id} value={id}>
                  {first_name} {last_name} {', '} ({email})
                </option>
              ))}
            </select>
          </div>
        </label>
        <label htmlFor='name' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Name
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <input
              onChange={onChangeHandler}
              type='text'
              placeholder='Name of the Contact'
              name='name'
              id='name'
              className='text-sm font-normal pl-2 w-full outline-none border-none'
            />
          </div>
        </label>
        <label htmlFor='whatsapp_number' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Whatsapp Number
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <input
              onChange={onChangeHandler}
              type='text'
              placeholder='WhatsApp Number'
              name='whatsapp_number'
              id='whatsapp_number'
              className=' text-sm font-normal pl-2 w-full outline-none border-none'
            />
          </div>
        </label>
        <label htmlFor='business_name' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Business Name
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <input
              onChange={onChangeHandler}
              type='text'
              placeholder='Name of thier Business'
              name='business_name'
              id='business_name'
              className=' text-sm font-normal pl-2 w-full outline-none border-none'
            />
          </div>
        </label>

        <label htmlFor='business_description' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Business Description
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <textarea
              onChange={onChangeHandler}
              name='business_description'
              id='business_description'
              placeholder='Write Business Description Here...'
              cols='50'
              rows='5'
              className='text-sm font-normal pl-2 w-full outline-none border-none'
            ></textarea>
          </div>
        </label>
        <label htmlFor='landmark_description' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Landmark Description
          <div className='relative w-full flex items-center border-2 mt-3 py-2 px-3 rounded-2xl'>
            <textarea
              onChange={onChangeHandler}
              name='landmark_description'
              id='landmark_description'
              cols='50'
              rows='5'
              placeholder='Write the Landmark Description here...'
              className='text-sm font-normal pl-2 w-full outline-none border-none'
            ></textarea>
          </div>
        </label>
        <label htmlFor='landmark_photo' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Landmark Photo
        </label>
        <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl '>
          <input
            onChange={onChangeHandler}
            className='pl-2 w-full outline-none border-none'
            type='file'
            name='landmark_photo'
            id='landmark_photo'
            placeholder='Select Ambassador...'
          />
        </div>

        <label htmlFor='locationAddress2' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Enter Place Name:
        </label>
        <input
          type='text'
          id='locationAddress2'
          className='w-full relative flex items-center border-2 mt-3 py-2 px-3 rounded-2xl'
          name=''
          placeholder='Type your location'
        />

        {btnLoad ? (
          <button
            type='submit'
            className='block w-1/2 bg-[#008080] mt-5 py-2 mx-auto rounded-md transition-all duration-500 text-white font-bold mb-2'
          >
            Saving...
          </button>
        ) : (
          <button
            type='submit'
            className='block w-1/2 bg-[#008080] mt-5 py-2 mx-auto rounded-md transition-all duration-500 text-white font-bold mb-2'
          >
            Save
          </button>
        )}
      </form>
    </div>
  );
}

export default OrderForm;
