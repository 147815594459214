import React from 'react';

import { TbClipboardList } from 'react-icons/tb';
import { IoIosAdd } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { CgSpinnerTwoAlt } from 'react-icons/cg';
import SingleOrder from './formComponents/SingleOrder';
import { GoDotFill } from 'react-icons/go';
import baseUrl from '../../BaseUrl';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data.data.access_token;
    fetch(`${baseUrl}/items/orders/`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setOrders(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className='w-full h-full flex-1  relative bg-white  py-2 rounded-md overflow-hidden '>
      <div className='w-full h-[50px] flex items-center justify-end  py-1 border rounded-md '>
        <div className='w-auto flex  items-center'>
          <input
            className='w-200px h-[40px] px-3 rounded-[20px] border focus:outline-none mr-2'
            type='search'
            placeholder='Search Items...'
          />
          <Link
            to='/orders/+'
            className='w-[40px] h-[40px] rounded-[50%] bg-gray-200 hover:bg-[#008080] transition-all duration-500 text-[25px] grid place-content-center cursor-pointer'
          >
            <IoIosAdd className='m-auto' />
          </Link>
        </div>
      </div>
      <div
        className={`w-full absolute h-[70%] ${
          Loading ? 'showgrid' : 'hide'
        } place-content-center `}
      >
        <CgSpinnerTwoAlt className='spinner' />
      </div>
      {orders ? (
        <div className='w-full height px-1  overflow-y-auto'>
          <table className='rwd-table '>
            <tr className='w-full below'>
              <th>No.</th>
              <th className='whitespace-nowrap text-center'>Contact Name</th>
              <th className='whitespace-nowrap text-center'>Product Name</th>
              <th className='whitespace-nowrap text-center'>Product Quantity</th>
              <th className='whitespace-nowrap text-center'>Status</th>
              <th className='whitespace-nowrap text-center'>Phone</th>
              <th className='whitespace-nowrap text-center'>Actions</th>
            </tr>            
            {orders &&
              orders.map(
                (
                  { product_quantity, product, status, id, order_contact_phone_number, contact },
                  index
                ) => (
                  <tr key={id} className='w-full hover:bg-gray-200 cursor-pointer border border-gray-100'>
                    <td className='whitespace-nowrap' data-th='No'>{index + 1}.</td>
                    <SingleOrder product={product} contact={contact} />
                    <td className='whitespace-nowrap ' data-th='Product Quantity'>
                      {' '}
                      {product_quantity? product_quantity : '--'}
                    </td>                    
                    <td className='whitespace-nowrap  p-2 border-r text-[#008080]  h-full ' data-th='Status'>
                      <GoDotFill className='md:mx-auto' title={status} />
                    </td>
                    <td className='whitespace-nowrap' data-th='Phone Number'>
                      {' '}
                      {order_contact_phone_number? order_contact_phone_number:'--'}
                    </td>
                    <td className='whitespace-nowrap' data-th='Actions'>
                      <button className='btn-edit'>Edit</button>
                      <button className='btn-delete'>Delete</button>
                    </td>
                  </tr>
                ),
              )}
          </table>
        </div>
      ) : (
        <div className='w-full h-full  grid  place-content-center'>
          <div className='min-w-[450px] min-h-[300px] rounded-md shadow-2xl flex flex-col items-center justify-evenly place-content-center'>
            <div className='w-[100px] h-[100px] grid place-content-center rounded-[50%] bg-gray-200 text-[#008080] text-[50px]'>
              <TbClipboardList />
            </div>
            <p className='w-full text-center font-bold text-[25px] text-gray-800'>No Items</p>
            <p className='w-full text-center opacity-70'>
              There are No Items in the collections yet
            </p>
            <Link
              to={'/orders/+'}
              className='w-auto py-2 px-4 bg-[#008080] rounded-md cursor-pointer'
            >
              Create Item
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Orders;
