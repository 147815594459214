import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Main from './Main';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const [sidebar, setSidebar] = useState(false);
  const [activePage, setActivePage] = useState('contacts_products');
  const navigate = useNavigate('');

  const info = JSON.parse(localStorage.getItem('loginUserData'));
  if (info === null) {
    window.history.pushState({}, 0, '/login');
    window.location.reload();
    return;
  }
  const handleSidebar = () => {
    if (sidebar) {
      setSidebar(false);
    } else setSidebar(true);
  };
  const data = JSON.parse(localStorage.getItem('loginUserData')) || '';
  if (!data) {
    navigate('/login');
  }
  const handleActiveSidebarLink = (activePage) => {
    setActivePage(activePage);
    navigate(`./${activePage}`);
  };
  return (
    <>
      <section className='w-screen flex flex-col h-screen bg-gray-100'>
        <Header handleSidebar={handleSidebar} />
        <div className='flex h-[93%] gap-2 py-1 px-2'>
          <Sidebar
            handleActiveSidebarLink={handleActiveSidebarLink}
            activePage={activePage}
            sidebar={sidebar}
            setSidebar={setSidebar}
          />
          <Main handleActiveSidebarLink={handleActiveSidebarLink} />
        </div>
      </section>
    </>
  );
}

export default Dashboard;
