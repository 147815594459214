import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import baseUrl from '../BaseUrl';

function Login() {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginError, setLoginError] = useState('');

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const onChangeHandler = (e) => {
    setFormData(() => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  };
  const submitFormData = (e) => {
    e.preventDefault();
    setFormErrors(validateFormData(formData));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setBtnLoad(true);
      fetch(`${baseUrl}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.data.access_token) {
            localStorage.setItem('loginUserData', JSON.stringify(res));
            navigate('/', { replace: true });
          } else {
            setBtnLoad(false);
            setLoginError(res.Error);
          }
        });
    }
  }, [formErrors, isSubmit, formData, navigate]);

  const validateFormData = (formData) => {
    const errors = {};
    if (!formData.email) {
      errors.email = 'Email is Required!';
    }
    if (!formData.password) {
      errors.password = 'Password is Required!';
    }
    return errors;
  };

  return (
    <div className='w-full h-screen p-1'>
      <div className='w-full h-full flex'>
        <div className='hidden lg:flex w-full lg:w-1/3 rounded-[20px] bg-[#008080] justify-around items-center'>
          <div className='w-full mx-auto h-full flex flex-col justify-around items-center '>
            <h1 className='text-white font-bold text-4xl '>
              Welcome To <br /> <br /> Azizi Africa
            </h1>
            <p className='text-white mt-1'>The simplest app to use</p>
            <div className='w-[90%] bg-[#000] rounded-md bg-opacity-20 h-[200px]  mt-6'></div>
          </div>
        </div>
        <div className='w-full flex h-full lg:w-2/3 justify-center items-center bg-white rounded-md  '>
          <div className='w-full px-3 md:px-10 lg:px-24'>
            <form
              onSubmit={submitFormData}
              className='w-[100%] m-auto bg-white rounded-md shadow-2xl p-2 '
            >
              <h1 className='w-full text-center text-gray-800 font-bold text-2xl mb-1'>Sign In</h1>
              <p
                className={`${
                  loginError ? 'show' : 'hide'
                } w-[80%] py-2 px-4 text-center rounded-md border border-red-700 bg-transparent text-red-700 m-auto`}
              >
                {loginError}
              </p>
              <label htmlFor='email'>Email</label>
              <div className='w-full flex items-center border-2 mb-4 py-2 px-3 rounded-2xl'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5 text-gray-400'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207'
                  />
                </svg>
                <input
                  onChange={onChangeHandler}
                  id='email'
                  className=' pl-2 w-full outline-none border-none'
                  type='email'
                  name='email'
                  placeholder='Email Address'
                />
              </div>
              <p className={`text-red-600 my-1 ${formData.email ? 'hidden' : 'block'}`}>
                {formErrors.email}
              </p>
              <label htmlFor='password'>Password</label>
              <div className='relative flex items-center border-2 mb-1 py-2 px-3 rounded-2xl '>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5 text-gray-400'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z'
                    clipRule='evenodd'
                  />
                </svg>
                <input
                  onChange={onChangeHandler}
                  className='pl-2 w-full outline-none border-none'
                  type={passwordVisible ? 'text' : 'password'}
                  name='password'
                  id='password'
                  placeholder='Password'
                />
                {passwordVisible ? (
                  <AiFillEyeInvisible
                    onClick={() => setPasswordVisible(false)}
                    className='absolute right-4 font-bold text-[24px] cursor-pointer'
                  />
                ) : (
                  <AiFillEye
                    onClick={() => setPasswordVisible(true)}
                    className='absolute right-4 font-bold text-[24px] cursor-pointer'
                  />
                )}
              </div>
              <p className={`text-red-600 my-1 ${formData.password ? 'hidden' : 'block'}`}>
                {formErrors.password}
              </p>
              <div className='flex justify-end mt-1 '>
                <span className='text-sm ml-2 text-[#008080] cursor-pointer duration-500 transition-all'>
                  Forgot Password ?
                </span>
              </div>
              {btnLoad ? (
                <button
                  type='submit'
                  className='block w-1/2 bg-[#008080] mt-5 py-2 mx-auto rounded-md transition-all duration-500 text-white font-bold mb-2'
                >
                  Signing In...
                </button>
              ) : (
                <button
                  type='submit'
                  className='block w-1/2 bg-[#008080] mt-5 py-2 mx-auto rounded-md transition-all duration-500 text-white font-bold mb-2'
                >
                  Sign in
                </button>
              )}

              <div className=' w-full grid place-items-center mb-2'>
                <p>
                  {'Don\'t have account?'}{' '}
                  <a
                    href='/signup'
                    className='font-bold hover:underline ml-2 cursor-pointer text-[#008080] '
                  >
                    Signup
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
