import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ErrorPage from './pages/ErrorPage';
import Dashboard from './pages/ambassadorPages/Dashboard';

function App() {
  
  return (
    <Routes>
      <Route path='/*' element={<Dashboard />} />
      <Route path='/login' element={<Login />} />
      <Route path='/signup' element={<Signup />} />
      <Route path='*' element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
