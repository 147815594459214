import { React, useEffect, useState } from 'react';
// import { MdOutlineModeEdit } from 'react-icons/md';
// import { RiDeleteBin6Line } from 'react-icons/ri';
import { TbClipboardList } from 'react-icons/tb';
import { GoDotFill } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { CgSpinnerTwoAlt } from 'react-icons/cg';
import baseUrl from '../../BaseUrl';

function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [Loading, setLoading] = useState(true);

  const pickContactId = (id) => {
    sessionStorage.setItem('contactId', JSON.stringify(id));
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data?.data?.access_token;

    fetch(`${baseUrl}/items/contacts/`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setContacts(res.data);
        setLoading(false);
      });
  }, []);

  return (
  //     <div className='flex-1 p-1 relative bg-white min-h-screen rounded-md overflow-x-auto'>
      
  //       {contacts ? (
  //         <div className='table w-full p-2 min-w-[640px]'>
  //           <table className='w-full border'>
  //             <thead>
  //               <tr className='bg-gray-50 border-b'>
  //                 <td className='whitespace-nowrap  p-2 border-r text-[#008080]   h-full ' data-th='Status'>
  //                   <GoDotFill className='md:mx-auto text-[30px]' />
  //                 </td>
  //                 <th className='p-2 border-r cursor-pointer text-[#008080]'>
  //                   <div className='flex items-center justify-center whitespace-nowrap'>
  //                     Business Name
  //                   </div>
  //                 </th>
  //                 <th className='p-2 border-r cursor-pointer text-[#008080]'>
  //                   <div className='flex items-center justify-center whitespace-nowrap'>
  //                     Location or Landmark
  //                   </div>
  //                 </th>
  //                 <th className='p-2 border-r cursor-pointer text-[#008080]'>
  //                   <div className='flex items-center justify-center whitespace-nowrap'>Name</div>
  //                 </th>
  //                 <th className='p-2 border-r cursor-pointer text-[#008080]'>
  //                   <div className='flex items-center justify-center whitespace-nowrap'>
  //                     Whatsapp Number
  //                   </div>
  //                 </th>
  //                 <th className='p-2 border-r cursor-pointer text-[#008080]'>
  //                   <div className='flex items-center justify-center whitespace-nowrap'>Action</div>
  //                 </th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               <div
  //                 className={`w-full absolute h-[70%] ${
  //                   Loading ? 'showgrid' : 'hide'
  //                 } place-content-center `}
  //               >
  //                 <CgSpinnerTwoAlt className='spinner' />
  //               </div>

  //               {contacts.map(
  //                 ({ business_name, location_or_landmark, name, id, whatsapp_number }) => (
  //                   <tr
  //                     id={id}
  //                     key={id}
  //                     className='bg-gray-100 text-center border-b text-sm text-gray-700 hover:cursor-pointer hover:bg-gray-200 transition-all duration-300'
  //                   >
  //                     <td className='p-2 border-r'>
  //                       <input
  //                         onClick={() => pickContactId(id)}
  //                         id={id}
  //                         className='accent-[#008080]'
  //                         type='radio'
  //                         name='contact'
  //                       />
  //                     </td>
  //                     <td className='p-2 border-r text-left'>{business_name ? business_name : '--'}</td>
  //                     <td className='p-2 border-r'>{location_or_landmark}</td>
  //                     <td className='p-2 border-r text-left'>{name}</td>
  //                     <td className='p-2 border-r text-left'>{whatsapp_number}</td>
  //                     <td className='flex place-content-center py-1'>
  //                       <p className=' duration-500 transition-all  p-2 inline-block rounded-[50%] hover:shadow-lg hover:bg-white text-[25px] text-red-600 font-thin'>
  //                         <RiDeleteBin6Line />
  //                       </p>
  //                       <p className=' duration-500 transition-all  w-10 inline-block rounded-[50%] p-2 hover:shadow-lg hover:bg-white text-[25px] text-[gray-600] font-thin'>
  //                         <MdOutlineModeEdit />
  //                       </p>
  //                     </td>
  //                   </tr>
  //                 ),
  //               )}
  //             </tbody>
  //           </table>
  //         </div>
  //       ) : (
  //         <div className='w-full h-full  grid  place-content-center'>
  //           <div className='min-w-[450px] min-h-[300px] rounded-md shadow-2xl flex flex-col items-center justify-evenly place-content-center'>
  //             <div className='w-[100px] h-[100px] grid place-content-center rounded-[50%] bg-gray-200 text-[#6644FF] text-[50px]'>
  //               <TbClipboardList />
  //             </div>
  //             <p className='w-full text-center font-bold text-[25px] text-gray-800'>No Items</p>
  //             <p className='w-full text-center opacity-70'>
  //               There are No Items in the collections yet
  //             </p>
  //             <Link
  //               to={'/contacts/+'}
  //               className='w-auto py-2 px-4 bg-[#008080] rounded-md cursor-pointer'
  //             >
  //               Create Item
  //             </Link>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   );
  // }
    <div className='w-full h-full flex-1 relative bg-white  py-2 rounded-md overflow-hidden '>      
      <div
        className={`w-full absolute h-[70%] ${
          Loading ? 'showgrid' : 'hide'
        } place-content-center `}
      >
        <CgSpinnerTwoAlt className='spinner' />
      </div>
      {contacts ? (
        <div className='w-full height px-1  overflow-y-auto'>
          <table className='rwd-table '>
            <tr className='w-full below'>
              <th>No.</th>
              <td className='hidden md:flex whitespace-nowrap  p-2 border-r text-[#008080]   h-full '>
                <GoDotFill className='md:mx-auto text-[30px]' />
              </td>
              <th className='whitespace-nowrap text-center'>Business Name</th>
              <th className='whitespace-nowrap text-center'>Location or Landmark</th>
              <th className='whitespace-nowrap text-center'>Name</th>
              <th className='whitespace-nowrap text-center'>Whatsapp Number</th>
              <th className='whitespace-nowrap text-center'>Actions</th>
            </tr>            
            {contacts &&
              contacts.map(
                (
                  { business_name, location_or_landmark, name, id, whatsapp_number },
                  index
                ) => (
                  <tr key={id} className='w-full border hover:bg-gray-200 cursor-pointer border-gray-100'>
                    <td className='whitespace-nowrap' data-th='No'>{index + 1}.</td>
                    <td className='whitespace-nowrap  p-2 border-r text-[#008080] h-full' data-th='Select'>
                      <input id={id}  onClick={() => pickContactId(id)} className=' mx-2 md:mx-0 cursor-pointer accent-[#008080]' type="radio" name="radio" />
                    </td>
                    <td className='whitespace-nowrap ' data-th='Business Name'>
                      {' '}
                      {business_name? business_name : '--'}
                    </td>
                    <td className='whitespace-nowrap ' data-th='Location or Landmark'>
                      {' '}
                      {location_or_landmark? location_or_landmark : '--'}
                    </td>                    
                    <td className='whitespace-nowrap' data-th='Name'>
                      {' '}
                      {name? name:'--'}
                    </td>
                    <td className='whitespace-nowrap ' data-th='Whatsapp Number'>
                      {' '}
                      {whatsapp_number? whatsapp_number : '--'}
                    </td>
                    <td className='whitespace-nowrap' data-th='Actions'>
                      <button className='btn-edit'>Edit</button>
                      <button className='btn-delete'>Delete</button>
                    </td>
                  </tr>
                ),
              )}
          </table>
        </div>
      ) : (
        <div className='w-full h-full  grid  place-content-center'>
          <div className='min-w-[450px] min-h-[300px] rounded-md shadow-2xl flex flex-col items-center justify-evenly place-content-center'>
            <div className='w-[100px] h-[100px] grid place-content-center rounded-[50%] bg-gray-200 text-[#008080] text-[50px]'>
              <TbClipboardList />
            </div>
            <p className='w-full text-center font-bold text-[25px] text-gray-800'>No Items</p>
            <p className='w-full text-center opacity-70'>
              There are No Items in the collections yet
            </p>
            <Link
              to={'/contacts/+'}
              className='w-auto py-2 px-4 bg-[#008080] rounded-md cursor-pointer'
            >
              Create Item
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}


export default Contacts;
