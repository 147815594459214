import React from 'react';
import { useEffect, useState } from 'react';
import { TbClipboardList } from 'react-icons/tb';
import { CgSpinnerTwoAlt } from 'react-icons/cg';
import { Link, useNavigate } from 'react-router-dom';
import Contacts from '../Contacts';
import baseUrl from '../../../BaseUrl';

const ContactsProducts = () => {
  const [productData, setProductData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [showProducts, setShowProducts] = useState(false);
  const [showContacts, setShowContacts] = useState(true);
  const [productId, setProductId] = useState('');
  const [contactId, setContactId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setInterval(() => {
      const id = JSON.parse(sessionStorage.getItem('contactId'));
      if (id) {
        setContactId(id);
      }
    }, 500);
  }, [setContactId]);

  const [formData, setFormData] = useState({
    status: 'null',
    average_quantity_pm: '',
    contact: {
      create: [
        {
          Contacts_Products_id: '+',
          contacts_id: {
            id: '',
          },
        },
      ],
      update: [],
      delete: [],
    },
    product: {
      create: [
        {
          Contacts_Products_id: '+',
          products_id: {
            id: '',
          },
        },
      ],
      update: [],
      delete: [],
    },
    unit_price: '',
    units: [],
    time_spent_in_days: '',
    product_use: '',
    contact_id: '',
    date_created: '',
    product_id: '',
    user_created: '',
  });
  const pickProductId = (id) => {
    setProductId(id);
  };

  const datas = JSON.parse(sessionStorage.getItem('loginUserData'));
  if (datas) {
    setTimeout(() => {
      navigate('/login', { replace: true });
    }, datas.data.expires);
  }

  const handleInputChange = (e) => {
    const data = JSON.parse(sessionStorage.getItem('userInfo'));
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      user_created: data.id,
      date_created: new Date().toISOString(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const checkboxes = document.querySelectorAll('.checkbox');
    const selectedProductIds = [];

    checkboxes.forEach((element, index) => {
      if (checkboxes[index].checked) {
        selectedProductIds.push(checkboxes[index].getAttribute('id'));
      }
    });

    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data.data.access_token;

    const requestDataf = {
      status: 'archived',
      average_quantity_pm: formData.average_quantity_pm,
      contact: {
        create: [
          {
            Contacts_Products_id: '+',
            contacts_id: {
              id: contactId,
            },
          },
        ],
        update: [],
        delete: [],
      },
      product: {
        create: [
          {
            Contacts_Products_id: '+',
            products_id: {
              id: productId,
            },
          },
        ],
        update: [],
        delete: [],
      },
      unit_price: formData.unit_price,
      units: [formData.units],
      time_spent_in_days: formData.time_spent_in_days,
      product_use: formData.product_use,
    };

    fetch(`${baseUrl}/items/Contacts_Products/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify(requestDataf),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data) {
          setShowProducts(false);
          setShowContacts(true);
          sessionStorage.removeItem('contactId');
        }
      });
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data.data.access_token;

    fetch(`${baseUrl}/items/products/`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.length) {
          setLoading(false);
          setProductData(res.data);
        }
      });
  }, []);

  useEffect(() => {
    if (contactId) {
      setShowProducts(true);
      setShowContacts(false);
    } else {
      setShowProducts(false);
      setShowContacts(true);
      // sessionStorage.removeItem('contactId');
    }
  }, [contactId]);

  return (
    <div className='w-full h-full flex-1 relative bg-white  py-2 rounded-md overflow-y-auto '>
      <div className='w-full min-h-10 flex justify-around '>
        <p>Choose Contact From Existing Contacts</p>
        <Link
          to={'contacts/+'}
          className='border border-[#008080] text-center rounded-md hover:bg-[#008080] hover:text-[#fff] transition duration-300 ease-linear font-semibold cursor-pointer min-h-7 px-2 leading-7'
        >
          Add New Contact
        </Link>
      </div>
      {showContacts ? (
        <div className='w-full border min-h-[160px]'>
          <Contacts />
        </div>
      ) : (
        ''
      )}
      {showProducts ? (
        <>
          <div className='w-full min-h-[50px] relative flex items-center justify-between px-3  py-1 border rounded-md '>
            <p>Add Product Details</p>
            <div className='w-auto flex  items-center'>
              <input
                className='w-200px h-[40px] mr-10 bg-white text-center px-3 rounded-[20px] border focus:outline-none '
                type='search'
                placeholder='Search Items...'
              />
              <button
                type='submit'
                onClick={handleSubmit}
                className='w-[40px] h-[40px]   rounded-[50%] bg-gray-200 hover:bg-[#008080] transition-all duration-500 text-sm grid place-content-center cursor-pointer'
              >
                Save
              </button>
            </div>
          </div>
          <div className='  w-full p-2 min-w-[640px]'>
            
            <div className='w-full height px-1  overflow-x-auto overflow-y-auto'>
              <table className='rwd-table remove'>
                <tr className='bg-gray-50 border-b'>
                  <th className='border-r p-2 w-full'>
                    <input className='accent-[#008080]' type='checkbox' />
                  </th>
                  <th className=' border-r cursor-pointer text-[#008080]'>
                    <td className='flex items-center w-full justify-center whitespace-nowrap'>
                      Product Name
                    </td>
                  </th>
                  <th className=' border-r cursor-pointer text-[#008080]'>
                    <td className='flex items-center justify-center whitespace-nowrap'>
                      Average Quantity/Month
                    </td>
                  </th>
                  <th className=' border-r cursor-pointer text-[#008080]'>
                    <td className='flex items-center justify-center whitespace-nowrap'>Unit Price</td>
                  </th>
                  <th className=' border-r cursor-pointer text-[#008080]'>
                    <td className='flex items-center justify-center whitespace-nowrap'>Units</td>
                  </th>
                  <th className=' border-r cursor-pointer text-[#008080]'>
                    <td className='flex items-center justify-center whitespace-nowrap'>
                      Time Spent (in days)
                    </td>
                  </th>
                  <th className=' border-r cursor-pointer text-[#008080]'>
                    <td className='flex items-center justify-center whitespace-nowrap'>
                      Product Use
                    </td>
                  </th>
                </tr>
                {Loading && (
                  <div
                    className={`w-full absolute  h-[70%] ${
                      Loading ? 'showgrid' : 'hide'
                    } place-content-center `}
                  >
                    <CgSpinnerTwoAlt className='spinner' />
                  </div>
                )}
                {!Loading && productData.length > 0
                  ? productData.map(({ name, id }) => (
                    <tr
                      key={id}
                      className='bg-gray-100 text-center border-b text-sm text-gray-700 hover:cursor-pointer hover:bg-gray-200 transition-all duration-300'
                    >
                      <td className='p-2 border-r' data-th='Select'>
                        <input
                          id={id}
                          onClick={() => pickProductId(id)}
                          className='accent-[#008080] checkbox ml-3'
                          type='checkbox'
                          required
                        />
                      </td>
                      <td id={id} className='p-2 border-r text-left ' data-th='Product Name'>
                        {' '} {name}
                      </td>
                      <td className='p-2 border-r w-full block' data-th='Average Quantity Per Month'>
                        <input
                          name='average_quantity_pm'
                          className='h-[40px] bg-white text-center w-full md:w-auto'
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          type='number'
                        />
                      </td>
                      <td className='p-2 border-r w-full block' data-th='Unit Price'>
                        <input
                          type='number'
                          min={0}
                          step='0.50'
                          name='unit_price'
                          className='h-[40px] bg-white text-center w-full md:w-auto'
                          onChange={handleInputChange}
                        />
                      </td>
                      <td className='p-2 border-r w-full block' data-th='Units'>
                        <select
                          onChange={handleInputChange}
                          className='h-[40px] bg-white text-center w-full md:w-auto'
                          name='units'
                          id='units'
                        >
                          <option selected disabled value=''>
                            Select Item...
                          </option>
                          <option value='Kg'>Kg(s)</option>
                          <option value='grams'>Gram(s)</option>
                          {/* <option value='sack'>Sack(s)</option> */}
                        </select>
                      </td>
                      <td className='p-2 border-r w-full block' data-th='Time Spent In Days'>
                        <input
                          onChange={handleInputChange}
                          type='number'
                          name='time_spent_in_days'
                          min={1}
                          step='1'
                          className='h-[40px] bg-white text-center w-full md:w-auto'
                        />
                      </td>
                      <td className='p-2 border-r w-full block' data-th='Product use'>
                        <textarea
                          onChange={handleInputChange}
                          className='w-full min-h-[60px] outline-none'
                          name='product_use'
                          id='product_use'
                          cols='50'
                          rows='2'
                        ></textarea>
                      </td>
                    </tr>
                  ))
                  : ''}
              </table>
            </div>
            
          </div>
        </>
      ) : (
        ''
      )}

      <div className='w-full h-full  hidden  place-content-center'>
        <div className='min-w-[450px] min-h-[300px] rounded-md shadow-2xl flex flex-col items-center justify-evenly place-content-center'>
          <div className='w-[100px] h-[100px] grid place-content-center rounded-[50%] bg-gray-200 text-[#008080] text-[50px]'>
            <TbClipboardList />
          </div>
          <p className='w-full text-center font-bold text-[25px] text-gray-800'>No Items</p>
          <p className='w-full text-center opacity-70'>There are No Items in the collections yet</p>
        </div>
      </div>
    </div>
  );
};

export default ContactsProducts;
