import React, { useEffect, useState } from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import { FaRegUser } from 'react-icons/fa';
import { AiOutlineBars } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import baseUrl from '../../BaseUrl';

function Header({ handleSidebar }) {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data.data.access_token;
    if (!accessToken) {
      navigate('/login');
      location.reload();
      return;
    } else {
      fetch(`${baseUrl}/users/me/`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.errors) {
            localStorage.clear();
          } else if (res.data) {
            setUserData(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [navigate]);

  if (!userData) {
    navigate('/login');
    window.location.reload();
  }

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      sessionStorage.setItem('userInfo', JSON.stringify(userData));
    }
  }, [userData]);

  if (!userData) {
    return null; // Render null if userData is null
  }

  const { first_name, id } = userData;

  return (
    <div id={id} className='w-full h-[7%] bg-white '>
      <div className='w-full h-full flex items-center justify-between px-2 gap-2'>
        <AiOutlineBars
          onClick={() => handleSidebar()}
          className='text-[35px] cursor-pointer p-1  font-bold ml-6 md:hidden'
        />
        <p className='hidden md:block min-w-[max-content] md:min-w-[200px] font-bold text-xl text-gray-900'>
          Ambassador
        </p>
        <div className='overflow-hidden flex items-center justify-between md:h-full'>
          <div className='min-w-[200px] rounded-[20px] overflow-hidden transition-all duration-500 flex p-[1px] hover:bg-gray-200 items-center justify-between md:pr-1 cursor-pointer'>
            <FaRegUser className='text-[35px] rounded-[50%] object-contain text-[#008080] p-1 bg-gray-300' />
            <p className='flex font-bold capitalize pr-4 whitespace-nowrap'>{first_name}</p>
            <AiOutlineDown className='hidden md:block text-[18px]' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
