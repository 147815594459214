import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import baseUrl from '../../../BaseUrl';

let json = {};

function OrderForm() {
  const [isSaved, setIsSaved] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [savedFormData, setSavedFormData] = useState(false);
  const [initialvalues, setInitialValues] = useState(json);
  const [contacts, setContacts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data.data.access_token;

    fetch(`${baseUrl}/items/products/`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.length) {
          setProducts(res.data);
        }
      });
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data?.data?.access_token;

    fetch(`${baseUrl}/items/locations/`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLocations(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('loginUserData'));
    const accessToken = data?.data?.access_token;

    fetch(`${baseUrl}/items/contacts/`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setContacts(res.data);
      });
  }, []);

  const handleInterchangeValue = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    json[key] = value;
    setInitialValues(json);
    initialvalues;
  };
  const [formData, setFormData] = useState({
    status: '',
    contact: '',
    product: '',
    order_contact_phone_number: '',
    location_or_landmark: '',
    product_quantity: '',
    pickup_or_delivery: '',
    pickup_or_delivery_date: '',
    order_frequency: '',
  });

  
  function deliveryLocation(info) {
    const data = info.latLng;
    sessionStorage.setItem('lat', JSON.stringify(data.lat));
    sessionStorage.setItem('long', JSON.stringify(data.lng));
  }

  function initAutocomplete() {
    const input = document.getElementById('locationAddress');
    const searchBox = new window.google.maps.places.SearchBox(input);
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }

      places.forEach((place) => {
        deliveryLocation({
          place_id: place.place_id,
          latLng: place.geometry.location.toJSON(),
          type: 'search',
        });
      });
    });
  }

  const script = document.createElement('script');
  script.src =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyBziMvajtCVRMk7ogCkiIyymQLfUtJBlC0&libraries=places&v=weekly';
  script.async = true;
  script.defer = true;
  script.onload = () => {
    initAutocomplete();
  };
  document.body.appendChild(script);

  const handleGoBack = () => {
    window.history.back();
  };

  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    setFormData(() => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  };
  const submitFormData = (e) => {
    e.preventDefault();
    setIsSaved(true);
    const info = JSON.parse(sessionStorage.getItem('userInfo'));
    const whoAdded = info.id;
    const date = new Date().toISOString();
    const latitude = JSON.parse(sessionStorage.getItem('lat'));
    const longitude = JSON.parse(sessionStorage.getItem('long'));
    let finalFormData = {
      ...formData,
      date_created: `${date}`,
      user_created: `${whoAdded}`,      
      custom_order_maps_pin: {
        type: 'Point',
        coordinates: [`${latitude}`, `${longitude}`],
      },
    };
    setSavedFormData(finalFormData);
  };
  useEffect(() => {
    if (isSaved) {
      setBtnLoad(true);
      const data = JSON.parse(localStorage.getItem('loginUserData'));
      const accessToken = data?.data?.access_token;

      fetch(`${baseUrl}/items/orders`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
        body: JSON.stringify(savedFormData),
      })
        .then((res) => res.json())
        .then(() => {
          setBtnLoad(false);
          navigate('/orders');
        })
        .catch((err) => console.log(err));
    }
  }, [isSaved, savedFormData, navigate]);

  return (
    <div className='w-full relative h-full flex items-center justify-center bg-white  rounded-md '>
      <IoIosArrowRoundBack
        onClick={handleGoBack}
        className='absolute left-6 top-2 text-[30px] h-[40px] w-[40px] bg-gray-300 transition-all duration-500 cursor-pointer hover:bg-[#008080] rounded-[50%]'
      />
      <form
        onSubmit={(e) => submitFormData(e)}
        className='bg-white min-w-[300px]  w-[95%] md:w-3/4 md:min-w-[450px] h-[90%] overflow-y-auto rounded-md shadow-2xl p-3 '
      >
        <h1 className='w-full text-center text-gray-800 font-bold text-2xl'>Add Order</h1>
        <label htmlFor='status' className='capitalize  pl-2 font-bold opacity-60 mt-1'>
          Status
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <select
              className='pl-2 text-sm font-normal w-full outline-none bg-transparent border-none'
              name='status'
              id='status'
              value={formData.status}
              onChange={onChangeHandler}
            >
              <option selected disabled value=''>
                Select Item...
              </option>
              <option value='draft'>Draft</option>
              <option value='archived'>Archived</option>
              <option value='published'>Published</option>
            </select>
          </div>
        </label>

        <label htmlFor='contact' className='capitalize  pl-2 font-bold opacity-60 mt-1'>
          Contact
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <select
              className='pl-2 text-sm font-normal w-full outline-none bg-transparent border-none'
              name='contact'
              id='contact'
              onKeyUp={(e) => handleInterchangeValue(e)}
              onChange={onChangeHandler}
            >
              <option selected disabled value=''>
                Select Contact...
              </option>
              {contacts.map(({ id, name, business_name }) => (
                <option key={id} value={id}>
                  {id} {', '} {name} ({'B/S Name: '} {business_name})
                </option>
              ))}
            </select>
          </div>
        </label>
        <label htmlFor='product' className='capitalize  pl-2 font-bold opacity-60 mt-1'>
          Product
          {/* <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <input
              onClick={() => navigateToProduct()}
              defaultValue={selectedProductId}
              onChange={onChangeHandler}
              onKeyUp={(e) => handleInterchangeValue(e)}
              type='text'
              placeholder='Select Product...'
              name='product'
              id='product'
              className=' text-sm font-normal pl-2 w-full outline-none border-none'
            />
          </div> */}
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <select
              className='pl-2 text-sm font-normal w-full outline-none bg-transparent border-none'
              name='product'
              id='product'
              onKeyUp={(e) => handleInterchangeValue(e)}
              onChange={onChangeHandler}
            >
              <option selected disabled value=''>
                Select Product...
              </option>
              {products.map(({ id, name }) => (
                <option key={id} value={id}>
                  {id} {', '} {name}
                </option>
              ))}
            </select>
          </div>
        </label>
        <label htmlFor='location_or_landmark' className='capitalize  pl-2 font-bold opacity-60 mt-1'>
          Location or Landmark
          {/* <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <input
              onClick={() => navigateToLocation()}
              defaultValue={selectedLocationId}
              onChange={onChangeHandler}
              onKeyUp={(e) => handleInterchangeValue(e)}
              type='text'
              placeholder='Select Product...'
              name='location_or_landmark'
              id='location_or_landmark'
              className=' text-sm font-normal pl-2 w-full outline-none border-none'
            />
          </div> */}
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <select
              className='pl-2 text-sm font-normal w-full outline-none bg-transparent border-none'
              name='location_or_landmark'
              id='location_or_landmark'
              onKeyUp={(e) => handleInterchangeValue(e)}
              onChange={onChangeHandler}
            >
              <option selected disabled value=''>
                Select Location...
              </option>
              {locations.map(({ id, location_landmark }) => (
                <option key={id} value={id}>
                  {id} {', '} {location_landmark}
                </option>
              ))}
            </select>
          </div>
        </label>
        <label htmlFor='product_quantity' className='capitalize  pl-2 font-bold opacity-60 mt-1'>
          Product Quantity
        </label>
        <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl '>
          <input
            onChange={onChangeHandler}
            className='pl-2 w-full outline-none border-none'
            type='text'
            name='product_quantity'
            id='product_quantity'
            placeholder='Quantity to Order'
          />
        </div>

        <label
          htmlFor='order_contact_phone_number'
          className='capitalize  pl-2 font-bold opacity-60 mt-1'
        >
          Order Contact Phone Number
        </label>
        <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl '>
          <input
            onChange={onChangeHandler}
            className='pl-2 w-full ml-2 outline-none  border-none'
            type='text'
            name='order_contact_phone_number'
            id='order_contact_phone_number'
            placeholder='If different form Contact Whatsapp Number'
          />
        </div>

        <label htmlFor='pickup_or_delivery' className='capitalize  pl-2 font-bold opacity-60 mt-1'>
          Pickup or Delivery
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <select
              className='pl-2 text-sm font-normal w-full outline-none bg-transparent border-none'
              name='pickup_or_delivery'
              id='pickup_or_delivery'
              onChange={onChangeHandler}
            >
              <option value='' selected disabled>
                Select Item...
              </option>
              <option value='pickup'>Pickup</option>
              <option value='delivery'>Delivery</option>
            </select>
          </div>
        </label>

        <label
          htmlFor='pickup_or_delivery_date'
          className='capitalize  pl-2 font-bold opacity-60 mt-1'
        >
          Pickup or Delivery Date
        </label>
        <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl '>
          <input
            onChange={onChangeHandler}
            className='pl-2 w-full outline-none border-none'
            type='date'
            name='pickup_or_delivery_date'
            id='pickup_or_delivery_date'
          />
        </div>

        <label htmlFor='order_frequency' className='capitalize  pl-2 font-bold opacity-60 mt-1'>
          Order Frequency
          <div className='relative flex items-center mb-2 border-2 mt-3 py-2 px-3 rounded-2xl'>
            <select
              className='pl-2 text-sm font-normal w-full outline-none bg-transparent border-none'
              name='order_frequency'
              id='order_frequency'
              value={formData.order_frequency}
              onChange={onChangeHandler}
            >
              <option value='' disabled>
                Select an Item
              </option>
              <option value='weekly'>Weekly</option>
              <option value='Bi-weekly'>Bi-weekly</option>
              <option value='monthly'>Monthly</option>
            </select>
          </div>
        </label>

        {/* <label htmlFor='locationAddress'>Enter Place Name:</label>
        <input type='text' id='locationAddress' name='' placeholder='Type your location' /> */}
        <label htmlFor='locationAddress' className='capitalize pl-2 font-bold opacity-60 mt-2'>
          Enter Place Name:
        </label>
        <input
          type='text'
          id='locationAddress'
          className='w-full relative flex items-center border-2 mt-3 py-2 px-3 rounded-2xl'
          name=''
          placeholder='Type your location'
        />

        {btnLoad ? (
          <button
            type='submit'
            className='block w-1/2 bg-[#008080] mt-5 py-2 mx-auto rounded-md transition-all duration-500 text-white font-bold mb-2'
          >
            Saving...
          </button>
        ) : (
          <button
            type='submit'
            className='block w-1/2 bg-[#008080] mt-5 py-2 mx-auto rounded-md transition-all duration-500 text-white font-bold mb-2'
          >
            Save
          </button>
        )}
      </form>
    </div>
  );
}

export default OrderForm;
