import React from 'react';
import Orders from '../mainComponents/Orders';
import ErrorPage from '../ErrorPage';
import { Routes, Route } from 'react-router-dom';
import ContactForm from '../mainComponents/formComponents/ContactForm';
import OrderForm from '../mainComponents/formComponents/OrderForm';
import ContactsProducts from '../mainComponents/formComponents/ContactsProducts';

function Main() {
  return (
    <Routes>
      <Route path='/' element={<ContactsProducts />} />
      <Route path={'/contacts_products'} element={<ContactsProducts />} />
      <Route path={'/contacts/+'} element={<ContactForm />} />
      <Route path={'/contacts_products/contacts/+'} element={<ContactForm />} />
      <Route path='/orders' element={<Orders />} />
      <Route path='/orders/+' element={<OrderForm />} />
      <Route path='/*' element={<ErrorPage />} />
    </Routes>
  );
}

export default Main;
